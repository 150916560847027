// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_t8";
export var lbContainerInner = "C_t9";
export var movingForwards = "C_vb";
export var movingForwardsOther = "C_vc";
export var movingBackwards = "C_vd";
export var movingBackwardsOther = "C_vf";
export var lbImage = "C_vg";
export var lbOtherImage = "C_vh";
export var prevButton = "C_vj";
export var nextButton = "C_vk";
export var closing = "C_vl";
export var appear = "C_vm";