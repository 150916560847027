// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "r_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "r_fR d_fR d_bz d_bJ";
export var menuDesign6 = "r_r6 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "r_r7 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "r_r8 d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "r_r9 d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "r_sb d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "r_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "r_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "r_sc";
export var navbarItem = "r_nb d_bx";
export var navbarLogoItemWrapper = "r_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "r_sd d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "r_sf d_gd d_by d_Z d_bs";
export var burgerToggle = "r_sg d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "r_sh d_gd d_by d_Z d_bs";
export var burgerInput = "r_sj d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "r_sk d_f3 d_w d_H";
export var burgerLine = "r_sl d_f1";
export var burgerMenuDesign6 = "r_sm d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "r_sn d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "r_sp d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "r_sq d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "r_sr d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "r_ss d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "r_st d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "r_sv d_bC d_bP";
export var compact = "r_sw";
export var navDivided = "r_sx";
export var staticBurger = "r_sy";
export var menu = "r_sz";
export var navbarDividedLogo = "r_sB";
export var nav = "r_sC";
export var fixed = "r_sD";