// extracted by mini-css-extract-plugin
export var alignLeft = "n_qD d_fp d_bG d_dv";
export var alignCenter = "n_bP d_fq d_bD d_dw";
export var alignRight = "n_qF d_fr d_bH d_dx";
export var container = "n_mw d_dW d_Z";
export var containerFull = "n_qG d_dT d_Z";
export var title = "n_qp d_cr";
export var text = "n_qc d_cx";
export var col = "n_qH d_bz";
export var link = "n_mC d_w d_H";
export var card = "n_qJ d_bz d_bD d_bN d_bJ d_H d_dw d_b5 d_Z";
export var bgImage = "n_qK d_bd d_0 d_8 d_7 d_4 d_9 d_bk";
export var overlay = "n_qL d_bd d_0 d_8 d_7 d_4 d_9";
export var content = "n_qM d_bz d_bD d_bJ";
export var iconBG = "n_qN d_b7 d_bz d_bD d_bP d_cr d_cG";
export var icon = "n_p9";
export var cardTitle = "n_qP d_w d_cs";
export var cardText = "n_qQ d_w";
export var SubtitleSmall = "n_qd q_qd q_qS q_q3";
export var SubtitleNormal = "n_qf q_qf q_qS q_q4";
export var SubtitleLarge = "n_qg q_qg q_qS q_q5";