// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sT d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_sV d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_sW d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_sX d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_sY d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_sZ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_s0 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rH q_rH";
export var heroExceptionNormal = "t_rJ q_rJ";
export var heroExceptionLarge = "t_rK q_rK";
export var Title1Small = "t_rm q_rm q_qS q_qT";
export var Title1Normal = "t_rn q_rn q_qS q_qV";
export var Title1Large = "t_rp q_rp q_qS q_qW";
export var BodySmall = "t_rB q_rB q_qS q_q9";
export var BodyNormal = "t_rC q_rC q_qS q_rb";
export var BodyLarge = "t_rD q_rD q_qS q_rc";